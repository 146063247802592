import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Tabla(){

    const [pedidos, setPedidos] = useState([]);
    const [dateTo, setDateTo] = useState(new Date());
    const [dateFrom, setDateFrom] = useState(new Date());
    

    const fetchData = async (start = '', end = '') => {
        try {
            let url;
            let response;
            if (start === '' || end === '') {
                url = `${process.env.REACT_APP_URL}/api/pedidos_info`;
            } else {
                url = `${process.env.REACT_APP_URL}/api/pedidos_info_filtrado/${start}/${end}`;
            }
            
            response = await fetch(url);
            const data = await response.json();
            setPedidos(data.pedidos);
        } catch (error) {
            console.error('Error fetching pedidos:', error);
        }
    }
        
    const handleFilter = () => {
        if (dateFrom.toString().includes('(') || dateTo.toString().includes<('(')) {
            alert('Las dos fechas tienen que ser ingresadas.');
            return;
        }

        if (new Date(dateFrom) >= new Date(dateTo)) {
            alert('La fecha de inicio tiene que ser menor a la fecha de término.');
            return;
        }
        fetchData(dateFrom, dateTo);
    }
        
    useEffect(() => {
        fetchData();
    }, []);

    
    const navigate = useNavigate();
    const handleVerMas = (id) => {
        navigate(`/web/pedidos/${id}`);
    }

    return (
        <div className='tabla'>
            <div className="filters">
                <label>
                    Start Date and Time:
                    <input 
                        type="datetime-local" 
                        value={dateFrom} 
                        onChange={(e) => setDateFrom(e.target.value)} 
                    />
                </label>
                <label>
                    End Date and Time:
                    <input 
                        type="datetime-local" 
                        value={dateTo} 
                        onChange={(e) => setDateTo(e.target.value)} 
                    />
                </label>
                <button onClick={handleFilter}>Filtrar</button>
            </div>
            <div className='contenedor-tabla'>
                <table>
                    <caption>Pedidos Actuales</caption>
                    <thead>
                        <tr>
                            <th>Id de pedido</th>
                            <th>Creada</th>
                            <th>Cliente</th>
                            <th>Proveedor</th>
                            <th>Estado</th>
                            <th>Vencimiento</th>
                            <th>SKUs y cantidad</th>
                            <th>Ver más</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pedidos.sort((a, b) => new Date(b.creada) - new Date(a.creada)).map((pedido) => (
                            <tr key={pedido.id+"informacion"}>
                                <td>{pedido.id}</td>
                                <td>{new Date(pedido.creada).toLocaleString('en-GB')}</td>
                                <td>{pedido.cliente}</td>
                                <td>{pedido.proveedor}</td>
                                <td>{pedido.estado}</td>
                                <td>{new Date(pedido.vencimiento).toLocaleString('en-GB')}</td>
                                <td>{pedido.sku}: {pedido.cantidad}</td>
                                <td>
                                    <button onClick={() => handleVerMas(pedido.id)}>Ver más</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
