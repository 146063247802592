import Tabla from './Tabla';
import Metricas from './Metricas/Metricas';
import CrearOrden from './CrearOrden';
import MetricaPedidos from './MetricaPedidos';
import Facturas from './Facturas';
import Balance from './Balance';
import BalanceEnElTiempo from './BalanceEnElTiempo';

export default function HomePage() {
  return (
    <div>
        <h1>App Grupo 2</h1>
        <Metricas />
        <CrearOrden />
        <MetricaPedidos />
        <Tabla />
        <BalanceEnElTiempo />
        <Balance />
        <Facturas />
    </div>
  );
}

