import { React, useState, useEffect } from 'react';

export default function MetricaEspacio() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/api/espacio_utilizado`);
                const dato_json = await response.json();
                setData(dato_json.espacioUtilizado);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching espacio:', error);
            }
        };

        fetchData();
    } , []);

    return (
        <div className='metrica'>
            <h2>Métrica de Espacio Utilizado</h2>
            {loading && <p>Cargando...</p>}
            <ul>
                {Object.entries(data || {}).map(([id, espacio]) => (
                    <li key={id+'espacio'}>
                        {espacio.nombre}: {espacio.utilizado} de {espacio.total}
                    </li>
                ))}
            </ul>
        </div>
    );
};