import { React, useState, useEffect } from 'react';

const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));


export default function MetricaVencimiento() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchVencimientosRequests = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/vencimientos_requests`); 
            const data = await response.json();
            return data.vencimientos;
        } catch (error) {
            console.error('Error fetching vencimientos requests', error);
            return [];
        }
    };

    const fetchVencimientos = async (espacio, productos) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/vencimientos/${espacio}/${productos}`);
            const data = await response.json();
            return data.vencimientos;
        } catch (error) {
            console.error('Error fetching vencimientos', error);
            return [];
        }
    };

    useEffect(() => {
        setLoading(true);
        const loadVencimientos = async () => {
            const vencimientosRequests = await fetchVencimientosRequests();
            const acumulados = [];
            console.log("Vencimientos Requests:", vencimientosRequests);
            for (const requestGroup of vencimientosRequests) {
                for (const item of requestGroup) {
                    const { espacio, productos } = item;
                    const vencimientos = await fetchVencimientos(espacio, productos);
                    acumulados.push(...vencimientos);
                    setData([...acumulados]);
                    setLoading(false);
                    console.log("Vencimientos:", vencimientos);
                    await wait(6000);
                }
            }
        };
        loadVencimientos();
    }, []);

    return (        
        <div className='metrica'>
            <h2>Métrica de Vencimiento</h2>
            {loading ? <p>Cargando...</p> :
            (data.length === 0 ? <p>No hay productos por vencer</p> :
            <ul className='lista-cambiante-vencimiento'>
                {(data || []).map((item, index) => (
                    <li key={index}>
                        <p>SKU: {item.sku}</p>
                        <p>Fecha de vencimiento: {new Date(item.vencimiento).toLocaleString()}</p>
                    </li>
                ))}
            </ul>)}
        </div>
    );
};
