import React, { useEffect, useState } from 'react';

const Balance = () => {
    const [balance, setBalance] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/api/balance`)
            .then(response => response.json())
            .then(data => setBalance(data.balance))
            .catch(error => console.error('Error fetching balance:', error));
    }, []);

    return (
        <div>
            <h1>Balance Actual</h1>
            {balance !== null ? <p>{balance}</p> : <p>Loading...</p>}
        </div>
    );
};

export default Balance;