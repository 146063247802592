import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export default function MetricaPedidos() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [showEmitidos, setShowEmitidos] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/api/npedidos_divididos`);
                const dato_json = await response.json();
                setData(dato_json);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching npedidos:', error);
            }
        };
        fetchData();
    }, []);

    // Define a color palette
    const colors = [
        'rgba(75, 192, 192, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(100, 100, 255, 1)'
    ];

    // Filter the data based on the toggle state
    const filteredData = Object.keys(data).reduce((acc, category) => {
        const isEmitido = category.includes('emitidos');
        if ((showEmitidos && isEmitido) || (!showEmitidos && !isEmitido)) {
            acc[category] = data[category];
        }
        return acc;
    }, {});

    // Get all unique hours across the selected categories to use as labels
    const allHours = new Set();
    Object.values(filteredData).forEach(categoryData => {
        categoryData.forEach(({ hora }) => allHours.add(hora));
    });
    const sortedHours = Array.from(allHours).sort((a, b) => a - b);

    // Process data into datasets for each category
    const chartData = {
        labels: sortedHours,  // X-axis labels are now the unique sorted hours
        datasets: Object.keys(filteredData).map((category, index) => ({
            label: category.charAt(0).toUpperCase() + category.slice(1).replace('_', ' '),  // Capitalize label
            data: sortedHours.map(hour => {
                const entry = filteredData[category].find(d => d.hora === hour);
                return entry ? entry.npedidos : 0;  // If hour data exists, use npedidos; otherwise, 0
            }),
            borderColor: colors[index % colors.length],
            backgroundColor: colors[index % colors.length],
            fill: false,
            tension: 0.1,
        })),
    };

    return (
        loading ? <p>Cargando...</p> :
        <div>
            <h2>Métrica de Pedidos por Hora</h2>
            <button onClick={() => setShowEmitidos(!showEmitidos)}>
                {showEmitidos ? 'Mostrar No Emitidos' : 'Mostrar Emitidos'}
            </button>
            <Line data={chartData} />
        </div>
    );
};
