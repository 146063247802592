import React from 'react';
import '../Progreso.css';

const Progreso = ({ order }) => {
    return (
        <table className="progress-table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Cantidad Actual</th>
                        <th>Cantidad Total</th>
                        <th>Progreso</th>
                    </tr>
                </thead>
            <tbody>
                {order.map((item, index) => {
                    const progreso = Math.min((item.entregados / item.cantidad) * 100, 100); // Calcula el progreso en porcentaje
                    return (
                        <tr key={index}>
                            <td>{item.sku}</td>
                            <td>{item.entregados}</td>
                            <td>{item.cantidad}</td>
                            <td>
                                <div className="progress-bar">
                                    <div
                                        className="progress-fill"
                                        style={{
                                            width: `${progreso}%`,
                                            backgroundColor: progreso >= 100 ? 'green' : '#4caf50',
                                        }}
                                    />
                                </div>
                                <span className="progress-text">{Math.round(progreso)}%</span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
    };

export default Progreso;
