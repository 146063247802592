import React, { useState, useEffect } from 'react';

export default function Facturas() {
    const [facturas, setFacturas] = useState([]);
    const [dateTo, setDateTo] = useState(new Date());
    const [dateFrom, setDateFrom] = useState(new Date());
    const [side, setSide] = useState('');
    const [status, setStatus] = useState('');

    const fetchData = async (start = '', end = '', side = '', status = '') => {
        try {
            let response;
            let body = {};

            if (start !== '') {
                body.date_from = start;
            }

            if (end !== '') {
                body.date_to = end;
            }

            if (side !== '') {
                body.side = side;
            }

            if (status !== '') {
                body.status = status;
            }

            response = await fetch(`${process.env.REACT_APP_URL}/api/facturas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
            const data = await response.json();
            setFacturas(data.facturas);
        } catch (error) {
            console.error('Error fetching facturas:', error);
        }
    }

    const handleFilter = () => {
        if (dateFrom.toString().includes('(') || dateTo.toString().includes('(')) {
            alert('Las dos fechas tienen que ser ingresadas.');
            return;
        }

        if (new Date(dateFrom) >= new Date(dateTo)) {
            alert('La fecha de inicio tiene que ser menor a la fecha de término.');
            return;
        }
        fetchData(dateFrom, dateTo);
    }

    const handleSide = (newSide) => {
        setSide(newSide);
        fetchData(dateFrom, dateTo, newSide, status);
    }

    const handleStatus = (newStatus) => {
        setStatus(newStatus);
        fetchData(dateFrom, dateTo, side, newStatus);
    }

    const handlePay = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/facturas/${id}/pay`, {
                method: 'POST',
            });
            const data = await response.json();
            if (data.error) {
                alert("Error pagando factura:", data.error);
            } else {
                alert("Factura pagada exitosamente:", data);
                fetchData(dateFrom, dateTo, side, status);
            }
        } catch (error) {
            alert("Error pagando factura");
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className='tabla'>
            <div className="filters">
                <label>
                    Start Date:
                    <input 
                        type="date" 
                        value={dateFrom} 
                        min={new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
                        max={new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]} 
                        onChange={(e) => setDateFrom(e.target.value)} 
                    />
                </label>
                <label>
                    End Date:
                    <input 
                        type="date" 
                        value={dateTo} 
                        min={new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
                        max={new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]} 
                        onChange={(e) => setDateTo(e.target.value)} 
                    />
                </label>
                <button onClick={handleFilter}>Filtrar</button>
                <div className="side-buttons">
                    <button onClick={() => handleSide('client')}>Client</button>
                    <button onClick={() => handleSide('supplier')}>Supplier</button>
                    <button onClick={() => handleSide('')}>All Sides</button>
                </div>
                <div className="status-buttons">
                    <button onClick={() => handleStatus('paid')}>Paid</button>
                    <button onClick={() => handleStatus('pending')}>Pending</button>
                    <button onClick={() => handleStatus('')}>All Statuses</button>
                </div>
            </div>
            <table>
                <caption>Facturas Actuales</caption>
                <thead>
                    <tr>
                        <th>Id de factura</th>
                        <th>Creada</th>
                        <th>Cliente</th>
                        <th>Proveedor</th>
                        <th>Estado</th>
                        <th>Precio Total</th>
                    </tr>
                </thead>
                <tbody>
                    {facturas.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((factura) => (
                        <tr key={factura.id}>
                            <td>{factura.id}</td>
                            <td>{new Date(factura.createdAt).toLocaleDateString('en-GB')}</td>
                            <td>{factura.client}</td>
                            <td>{factura.supplier}</td>
                            <td>{factura.status}</td>
                            <td>
                                {factura.totalPrice} 
                                {(factura.client === '2') && (factura.status === 'pending') && <button onClick={() => handlePay(factura.id)}>Pay</button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}