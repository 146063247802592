import { React, useState, useEffect } from 'react';
import { useParams,  useNavigate } from 'react-router-dom';
import Progreso from './Progreso';



export default function Pedido(){
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [pedido, setPedido] = useState({});
    const [skus, setSkus] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/api/pedido/` + id);
                const data = await response.json();
                setPedido(data.pedido);
                setSkus(data.skus);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching pedido:', error);
            }
        };
        
        fetchData();
    }, [id]);

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    }

    return (
        loading ? <p>Cargando...</p> :
        <div className='pedido'>
            <h2>Pedido</h2>
            <p>id: {pedido.id}</p>
            <p>Creada: {new Date(pedido.creada).toLocaleString('en-GB')}</p>
            <p>Actualizada: {new Date(pedido.actualizada).toLocaleString('en-GB')}</p>
            <p>Cliente: {pedido.cliente}</p>
            <p>Proveedor: {pedido.proveedor}</p>
            <p>Despachado: {pedido.despachado}</p>
            <p>Estado: {pedido.estado}</p>
            <p>Vencimiento: {new Date(pedido.vencimiento).toLocaleString('en-GB')}</p>
            <p>Tipo: {pedido.tipo}</p>
            <ul>
                {skus.map((sku) => (
                    <li key={sku.sku}>{sku.sku}: {sku.cantidad}</li>
                ))}
            </ul>
            <button onClick={handleGoBack}>Volver</button>
            <Progreso order={skus} />
        </div>
    );
};