import React, { useState, useEffect } from 'react';

const OrderCreation = () => {
    const [item, setItem] = useState(null);
    const [sku, setSku] = useState('');
    const [quantity, setQuantity] = useState('');
    const [group, setGroup] = useState('');
    const [message, setMessage] = useState('');
    const [availableGroups, setAvailableGroups] = useState([]);
    const [availableSkus, setAvailableSkus] = useState([]);
    const [isItemAdded, setIsItemAdded] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [generandoOrden, setGenerandoOrden] = useState(false);

    const fetchAvailableSkus = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/obtener_skus`);
            if (!response.ok) throw new Error('Error al obtener los SKUs');

            const data = await response.json();
            const skus = Object.keys(data);
            setAvailableSkus(skus);
        } catch (error) {
            console.error('Error al obtener los SKUs:', error);
        }
    };

    const fetchGroupsForSKU = async (selectedSku) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/obtener_skus`);
            if (!response.ok) throw new Error('Error al obtener los grupos');

            const data = await response.json();
            const groups = data[selectedSku] || [];
            setAvailableGroups(groups);
            setGroup(groups[0] || '');
        } catch (error) {
            console.error('Error al obtener los grupos:', error);
        }
    };

    useEffect(() => {
        fetchAvailableSkus();
    }, []);

    useEffect(() => {
        if (sku) fetchGroupsForSKU(sku);
    }, [sku]);

    const resetForm = () => {
        setItem(null);
        setGroup('');
        setIsItemAdded(false);
    };

    const handleAddItem = () => {
        if (!sku || !quantity) {
            setMessage('Por favor, selecciona un SKU y una cantidad.');
            triggerErrorPopup();
            return;
        }
        setItem({ sku, quantity });
        setIsItemAdded(true);
    };

    const handleOrderCreation = async () => {
        setGenerandoOrden(true);
        try {
            if (!item || !group) {
                setMessage('Por favor, selecciona un ítem y un proveedor.');
                triggerErrorPopup();
                return;
            }
    
            const currentDate = new Date();
            const expirationDate = new Date(currentDate);
            expirationDate.setDate(currentDate.getDate() + 3);
    
            const orderData = {
                cliente: "2", 
                proveedor: group,
                sku: item.sku,
                cantidad: item.quantity,
                vencimiento: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toISOString(), // 2 days from now
            };

            const response = await fetch(`${process.env.REACT_APP_URL}/api/crear_orden`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(orderData),
            });
            const responseData = await response.json();
            if (response.status === 200) {
                setMessage();
                setOrderId(responseData.id_orden_de_compra);
                setGenerandoOrden(false);
            } else {
                setMessage(`Error al crear la orden: ${responseData.error}`);
                triggerErrorPopup();
            }
        } catch (error) {
            setGenerandoOrden(false);
            setMessage(`Error: ${error.message}`);
            triggerErrorPopup();
        }
    };
    

    const triggerErrorPopup = () => {
        setGenerandoOrden(false);
        setOrderId('');
        setShowErrorPopup(true);
        setTimeout(() => {
            setShowErrorPopup(false); // Oculta el popup de error
        }, 3000); // 3 segundos de espera antes de resetear
    };
    
    return (
        <div className="order-page">
            <div className="order-container">
                <h1>Crear Orden</h1>

                <div className="input-group">
                    <label>Seleccionar SKU</label>
                    <select 
                        value={sku} 
                        onChange={(e) => setSku(e.target.value)}
                        disabled={isItemAdded}
                        >
                        <option value="" disabled>Seleccione el SKU del producto</option>
                        {availableSkus.map((sku) => (
                            <option key={sku} value={sku}>
                                {sku}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="input-group">
                    <label>Cantidad</label>
                    <input
                        type="number"
                        min="1"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        placeholder="Seleccione la cantidad"
                        disabled={isItemAdded}
                    />
                </div>
                <button 
                    onClick={handleAddItem} 
                    disabled={isItemAdded}
                >
                    Agregar Ítem
                </button>

                {item && (
                    <div className="item-details">
                        <h1>Orden</h1>
                        <div>
                            <p>{item.sku}: {item.quantity}</p>
                            <button onClick={resetForm}>Eliminar</button>
                        </div>                      
                        <div className="input-group">
                            <label>Seleccionar Proveedor</label>
                            <select value={group} onChange={(e) => setGroup(e.target.value)}>
                                <option value="" disabled>Seleccione el proveedor</option>
                                {availableGroups.length > 0 ? (
                                    availableGroups.map((num) => (
                                        <option key={num} value={num} disabled={num === "2"}>
                                        {num}
                                        </option>
                                    ))
                                ) : (
                                    <option>No hay proveedores disponibles</option>
                                )}
                            </select>
                        </div>

                        <button onClick={handleOrderCreation}>Generar Orden</button>
                    </div>
                )}
                {generandoOrden ? (
                    <div className="generando-orden">
                        <p>Generando orden...</p>
                    </div>
                ) : (
                    orderId && (
                        <div className="order-id">
                            <p>Orden generada con éxito. ID de la orden: {orderId}</p>
                        </div>
                    )
                )}
                
                {showErrorPopup && (
                    <div className="error-popup">
                        <p style={{ color: 'red', backgroundColor: '#ffe6e6', padding: '10px', borderRadius: '5px' }}>
                            {message}
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderCreation;
