import { React, useState, useEffect  } from 'react';

export default function MetricaStock() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/api/stock`);
                const dato_json = await response.json();
                setData(dato_json.stock);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching stock:', error);
            }
        }
        fetchData();
    }, []);


    return (
        <div className='metrica'>
            <h2>Métricas de Stock</h2>
            {loading && <p>Cargando...</p>}
            <ul className='lista-cambiante'>
                {(data || []).map((item) => (
                    <li key={item.sku}>
                        SKU: {item.sku} - Stock: {item.stock}
                    </li>
                ))}
            </ul>
        </div>
    );
};
