import Tabla from './Tabla';
import Metricas from './Metricas/Metricas';
import CrearOrden from './CrearOrden';
import MetricaPedidos from './MetricaPedidos';

export default function HomePage() {
  return (
    <div>
        <h1>App Grupo 2</h1>
        <Metricas />
        <CrearOrden />
        <MetricaPedidos />
        <Tabla />
    </div>
  );
}

