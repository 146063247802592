import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';

Chart.register(TimeScale);

const BalanceEnElTiempo = () => {
    const [balanceData, setBalanceData] = useState([]);

    const fetchBalanceData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/balance_en_el_tiempo`);
            const data = await response.json();
            const threeDaysAgo = new Date();
            threeDaysAgo.setDate(threeDaysAgo.getDate() - 1);
            const filteredData = data.balance.filter(item => new Date(item.fecha) >= threeDaysAgo);
            setBalanceData(filteredData);
        } catch (error) {
            console.error('Error fetching balance data:', error);
        }
    }

    useEffect(() => {
        fetchBalanceData();
    }, []);

    const data = {
        labels: balanceData.map(item => new Date(item.fecha)),
        datasets: [
            {
                label: 'Balance en el Tiempo',
                data: balanceData.map(item => item.balance),
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'hour',
                    stepSize: 1,
                    displayFormats: {
                        hour: 'dd/MM - hh a',
                    },
                },

            },
            y: {
                beginAtZero: false,
                suggestedMin: Math.min(...balanceData.map(item => item.balance)) - 10,
                suggestedMax: Math.max(...balanceData.map(item => item.balance)) + 10,
            },
        },
    };

    return (
        <div>
            <h2>Balance en el Tiempo</h2>
            <Line data={data} options={options} />
        </div>
    );
};

export default BalanceEnElTiempo;