// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-table {
    width: 100%;
    border-collapse: collapse;
}

.progress-table th,
.progress-table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.progress-bar {
    position: relative;
    width: 100%;
    background-color: #c5c5c5;
    border-radius: 4px;
    height: 20px;
    overflow: hidden;
}

.progress-fill {
    height: 100%;
    border-radius: 4px;
}

.progress-text {
    font-size: 12px;
    display: block;
    margin-top: 4px;
}
  `, "",{"version":3,"sources":["webpack://./src/Progreso.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;;IAEI,YAAY;IACZ,6BAA6B;IAC7B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,eAAe;AACnB","sourcesContent":[".progress-table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.progress-table th,\n.progress-table td {\n    padding: 8px;\n    border-bottom: 1px solid #ddd;\n    text-align: center;\n}\n\n.progress-bar {\n    position: relative;\n    width: 100%;\n    background-color: #c5c5c5;\n    border-radius: 4px;\n    height: 20px;\n    overflow: hidden;\n}\n\n.progress-fill {\n    height: 100%;\n    border-radius: 4px;\n}\n\n.progress-text {\n    font-size: 12px;\n    display: block;\n    margin-top: 4px;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
