import './App.css';
import HomePage from './components/HomePage';
import Pedido from './components/Pedido';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import CrearOrden from './components/CrearOrden';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/web" element={<HomePage />} />
          <Route path="/web/pedidos/:id" element={<Pedido />} />
          <Route path="/web/crear-orden" element={<CrearOrden />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
