import React from 'react';
import MetricaEspacio from './MetricaEspacio.js';
import MetricaStock from './MetricaStock.js';
import MetricaVencimiento from './MetricaVencimiento.js';

export default function Metricas() {
    
    return (
        <div className="container-metrica">
            <div className="left-column">
                <MetricaEspacio />
                <MetricaStock />
            </div>
            <div className="right-column">
                <MetricaVencimiento />
            </div>
        </div>
    );
};